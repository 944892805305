import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import config from "../../gatsby-config"
import MainHeader from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'

const BodyContainer = styled.div`
  padding: 0 200px 35px 200px;
`

class ExamplesPage extends React.Component {
  render() {
    return (
      <Layout>

        <React.Fragment>

          <Helmet>
            <title>{`Examples | ${config.siteMetadata.siteTitle}`}</title>
          </Helmet>

          <main>

            <MainHeader />

            <BodyContainer>

              <h2>Examples</h2>

              <p>This is a collection of examples to help you get familiar with the Scalepoint API&apos;s.</p>

              <p>Official repo of our examples at GitHub: <a href="https://github.com/scalepoint-tech/examples">https://github.com/scalepoint-tech/examples</a>.</p>

              <h4>Scalepoint ID Get Token</h4>

              <p>This example shows the principles behind <a href="/authentication">Scalepoint Authentication</a></p>

              <ul>
                <li><a href="https://github.com/scalepoint-tech/examples/tree/master/dotnet/scalepoint-id-get-token">.NET, C#</a></li>
              </ul>

              <h2>Libraries</h2>

              <p>Scalepoint provides simple to use client helper libraries in Java/.NET that abstract token generation away from you.</p>

              <ul>
                <li>In .NET, you can simply use this helper to get access tokens: <a href="https://github.com/scalepoint-tech/oauth-token-net-client#getting-started">https://github.com/scalepoint-tech/oauth-token-net-client#getting-started</a></li>
                <li>In Java, you can use a similar one: <a href="https://github.com/scalepoint-tech/oauth-token-java-client#getting-started">https://github.com/scalepoint-tech/oauth-token-java-client#getting-started</a></li>
              </ul>

            </BodyContainer>

          </main>

          <Footer />

        </React.Fragment>

      </Layout>

    );
  }
}

export default ExamplesPage;
